import '../app/extensions';

export const environment = {
  production: false,
  devTeam: window['ngEnv'].dev.team,
  apiUrl: `https://${window['ngEnv'].dev.team}-ptg-penapp-api-${window['ngEnv'].dev.env}.${window['ngEnv'].dev.apexdomain}`,
  clientMediaPath: `https://st${window['ngEnv'].dev.team}edge${window['ngEnv'].dev.env}${window['ngEnv'].dev.location}.blob.core.windows.net/media/${window.location.getClientDomain()}`,
  appInsights: {
    instrumentationKey: window['ngEnv'].appInsightsKey
  },
  http: {
    retry: 5,
    timeout: 5000
  },
  auth0Config: {
    config: {
      domain: "auth.ptgppplus.org",
      clientId: "2EplpAIS4F28xKDyl81kSQeBjNrE6oQI",
      audience: "https://ptgportaldevuat.onmicrosoft.com/ptg-mobile",
      issuer: "https://auth.ptgppplus.org/",
      redirectUri: window.location.origin,
      errorPath: "/error",
      httpInterceptor: {
        allowedList: [{
          uri: '*',
          allowAnonymous: true,
        }],
      },
      appKey: 'PensionApp',
      apiUrl: `https://${window['ngEnv'].dev.team}-ptg-penapp-api-${window['ngEnv'].dev.env}.${window['ngEnv'].dev.apexdomain}`,
      clientMediaPath: `https://st${window['ngEnv'].dev.team}edge${window['ngEnv'].dev.env}${window['ngEnv'].dev.location}.blob.core.windows.net/media/${window.location.getClientDomain()}`,
      client_domain: window.location.getClientDomain(),
      databaseConnection: 'Username-Password-Authentication',
      devTeam: window['ngEnv'].dev.team,
    },
    claimNamespace: 'https://ptg.pension.com/'
  }
};
